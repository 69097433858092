<template>
  <div class="is-flex align-items-center">
    <asset-image location="thumbnail" :image-url="asset.image_url"/>
    <div>
      <router-link
          :to="{
          name: 'asset-overview',
          params: {
            asset: asset.id,
          }
        }">
        <span class="has-text-weight-semibold">
          <span v-if="asset.code">{{ asset.code }}</span>
          <span v-if="asset.barcode && !asset.code">{{ asset.barcode.data }}</span>
        </span>
      </router-link>
      <div class="has-text-weight-normal has-text-grey-dark" >{{subText}}</div>
    </div>
  </div>
</template>

<script>
import AssetImage from "@/views/asset/partials/AssetImage.vue";

export default {
  components: {AssetImage},
  props: {
    asset: {
      type: Object,
      default: () => ({})
    },
    subText: {
      type: String,
      default: '--'
    }
  }
}
</script>
