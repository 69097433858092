<template>
<div
    class="new-feature-tag has-tooltip-top has-tooltip-multiline"
    :data-tooltip="text">
    New
</div>
</template>
<script>
export default {
  props: {
    tooltipText: {
      type: String,
      default: null,
    },
  },
  computed: {
    text(){
      return this.tooltipText.toLowerCase()
    }
  }
};
</script>